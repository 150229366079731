export const supportWebP = () => {
  try {
    const canvas = document.createElement('canvas');
    if (!canvas.getContext('2d')) {
      return false;
    }
    const data = canvas.toDataURL('image/webp');
    return data.startsWith('data:image/webp');
  } catch (err) {
    return false;
  }
};